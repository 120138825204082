export const FirebaseConfig = {
	"projectId": "readyride-7f42e",
	"appId": "1:57436426725:web:d3fb938c226e23ca425f0c",
	"databaseURL": "https://readyride-7f42e-default-rtdb.firebaseio.com",
	"storageBucket": "readyride-7f42e.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDvP6IELEmdkudoSEgMOUkUuKR-9Zwbqoo",
	"authDomain": "readyride-7f42e.firebaseapp.com",
	"messagingSenderId": "57436426725",
	"measurementId": "G-D3P14276MD"
};